import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { CardContent, Collapse, Typography } from "@mui/material";

import { MainDrowerContext } from "../../provider/MainDrowerProvider";
import { getTranslation, useMapState } from "../../provider/MapStateProvider";

const shortCommitLenght = 10;

const Guide = () => {
  const { data } = useMapState();
  const { setData, expanded } = useContext(MainDrowerContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setData({
        image: data.image || data.icon || "",
        title: getTranslation(data.name, "pl"),
        description: getTranslation(data.description, "pl"),
      });
    }
    return () => setData(null);
  }, [data]);

  return (
    <>
      {data && (
        <Collapse in={expanded}>
          <CardContent>
            <Typography paragraph color="text.secondary">
              {getTranslation(data.description, "pl")}
            </Typography>
            <Typography variant="h5">
              {getTranslation(data.interface.author.name, "pl")}
            </Typography>
            <Typography paragraph color="text.secondary">
              {getTranslation(data.interface.author.description, "pl")}
            </Typography>

            <Typography variant="h6">Wersja:</Typography>
            <ul>
              <li>Aplikacja: {process.env.REACT_APP_VERSION}</li>
              <li>Mapa: {data.version}</li>
              <li>
                Build:{" "}
                {process.env.REACT_APP_CF_PAGES_COMMIT_SHA?.slice(
                  0,
                  shortCommitLenght
                )}
              </li>
            </ul>
          </CardContent>
        </Collapse>
      )}
    </>
  );
};

export default Guide;
