import React, { createContext, useState } from "react";
import { TranslationString } from "./MapStateProvider";

export enum RepoDataGuideType {
  APP_DATA_JSON = "APP_DATA_JSON",
  GOOGLE_LAYER_KML = "GOOGLE_LAYER_KML",
}

export interface RepoDataGuide {
  id: string;
  path: string;
  name: TranslationString | string;
  description: TranslationString | string;
  "repo-type": RepoDataGuideType;
  coordinates: number[];
  image: string;
  icon: string;
}

export interface RepoData {
  local: RepoDataGuide[];
  version: {
    fileFormat: string;
    repo: string;
  };
}

interface RepoDataState {
  data: RepoData | null;
  setData: (data: RepoData | null) => void;
}

const defaultState: RepoDataState = {
  data: null,
  setData: function (): void {
    throw new Error(
      "RepoDataContextProvider.setData:Function not implemented."
    );
  },
};

export const RepoDataContext = createContext(defaultState);

export default function RepoDataContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [data, setData] = useState<RepoData | null>(null);

  return (
    <RepoDataContext.Provider value={{ data, setData }}>
      {children}
    </RepoDataContext.Provider>
  );
}
