import { Navigate, Route, Routes } from "react-router-dom";

import App from "./App";
import Branding from "./page/Branding/Branding";
import BrandingRouter from "./page/Branding/BrandingRouter";
import Guide from "./page/Guide/Guide";
import Point from "./page/Point/Point";
import PrivacyPolicy from "./page/PrivacyPolicy/PrivacyPolicy";

interface Props {}

const AppRouter = (props: Props): JSX.Element => {
  return (
    <Routes>
      {/* <Route path="generic-guide/:appid" element={<App />}> */}
      <Route path="branding/*" element={<BrandingRouter />} />
      <Route path="privacy-policy/*" element={<PrivacyPolicy />} />
      {/* <Route path="branding/:appid" element={<Guide />}></Route>
      <Route
        path="branding/:appid/:collectioniterator/:itemiterator"
        element={<Point />}
      />
      <Route index element={<Guide />} /> */}
      {/* </Route>
      <Route
        path="*"
        element={<Navigate to="generic-guide/dzieci-balut"></Navigate>}
      ></Route> */}
    </Routes>
  );
};

export default AppRouter;
