import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { kml } from "@tmcw/togeojson";
import { Point, GeoJsonProperties } from "geojson";

import {
  getTranslation,
  MapCollection,
  MapData,
  MapItem,
  useMapState,
} from "../../provider/MapStateProvider";
import {
  RepoData,
  RepoDataContext,
  RepoDataGuide,
  RepoDataGuideType,
} from "../../provider/RepoDataProvider";

import config from "../../config.json";
import { useQuery } from "@tanstack/react-query";

const notFoundPath = "not-found";

function getGuideDataPath(guideData: RepoDataGuide): string {
  switch (guideData["repo-type"]) {
    case RepoDataGuideType.APP_DATA_JSON:
      return `${guideData.path}/data.json`;
    case RepoDataGuideType.GOOGLE_LAYER_KML:
      return `${guideData.path}/layer.kml`;
    default:
      throw new Error(
        `Branding: getGuideDataPath: Not implemented guide data type ${guideData["repo-type"]}`
      );
  }
}

function mapKmlToAppData(
  data: any,
  repoDataGuide: RepoDataGuide,
  repoData: RepoData
): MapData {
  const dom = new DOMParser().parseFromString(data, "text/xml");

  const geoJSON = kml(dom);

  const collections: MapCollection[] = [
    {
      items: geoJSON.features.map((feature) => {
        const properties = feature.properties as GeoJsonProperties;

        const item: MapItem = {
          coordinates: (feature.geometry as Point).coordinates
            .slice(0, 2)
            .reverse(),
          name: properties?.name,
          description: (properties?.description?.value as string)
            .split("<br><br>")[1]
            .replaceAll("<br>", ""),
          locationDescription: "",
          images: properties?.gx_media_links
            .split(" ")
            .map((url: string) => ({ url })),
        };
        return item;
      }),
    },
  ];

  return {
    collections,
    description: repoDataGuide.description,
    interface: {
      author: {
        name: {},
        description: {},
      },
    },
    name: repoDataGuide.name,
    coordinates: repoDataGuide.coordinates,
    image: repoDataGuide.image,
    icon: repoDataGuide.icon,
    version: repoData.version.repo,
  };
}

export default function Branding(): JSX.Element {
  const { appId } = useParams();
  const { selected } = useMapState();
  const navigate = useNavigate();
  const { data: repoData } = useContext(RepoDataContext);
  const { setData } = useMapState();
  const [guideDataPath, setGuideDataPath] = useState<string | null>(null);
  const [repoDataGuideType, setRepoDataGuideType] = useState<RepoDataGuideType>(
    RepoDataGuideType.APP_DATA_JSON
  );
  const [repoDataGuide, setRepoDataGuide] = useState<RepoDataGuide | null>(
    null
  );

  const { isLoading, error, data, isFetching } = useQuery<MapData>(
    ["repoData"],
    () => {
      return axios
        .get(`${config.repos[0]}/${guideDataPath}`)
        .then((res) => res.data);
    },
    {
      enabled: !!guideDataPath,
    }
  );

  useEffect(
    function getGuidePath() {
      if (!appId) {
        return;
      }

      if (repoData) {
        const guideData = repoData.local.find((repo) => repo.id === appId);
        if (guideData) {
          setRepoDataGuideType(guideData["repo-type"]);
          setRepoDataGuide(guideData);
          setGuideDataPath(getGuideDataPath(guideData));
        } else {
          navigate({ pathname: `./${notFoundPath}` });
        }
      }
    },
    [repoData, appId]
  );

  useEffect(
    function setGuideData() {
      if (data === undefined || repoDataGuide === null || repoData === null) {
        return;
      }

      switch (repoDataGuideType) {
        case RepoDataGuideType.APP_DATA_JSON:
          document.title = getTranslation(data.name, "pl");
          return setData(data);
        case RepoDataGuideType.GOOGLE_LAYER_KML:
          const mapData = mapKmlToAppData(data, repoDataGuide, repoData);
          document.title = getTranslation(mapData.name, "pl");
          return setData(mapData);
        default:
          throw new Error(
            `Branding: setGuideData: Not implemented guide data type ${repoDataGuideType}`
          );
      }
    },
    [data, repoDataGuideType, repoDataGuide]
  );

  useEffect(
    function navigateToSelected() {
      if (!appId) {
        return;
      }

      if (selected) {
        navigate({
          pathname: `./${appId}/${selected.collection}/${selected.item}`,
        });
      } else {
        navigate({ pathname: `./${appId}` });
      }
    },
    [selected]
  );

  return (
    <>
      <Outlet />
    </>
  );
}
