import { CardContent, CardMedia, Collapse, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainDrowerContext } from "../../provider/MainDrowerProvider";
import {
  getTranslation,
  MapData,
  MapItem,
  useMapState,
} from "../../provider/MapStateProvider";
import Divider from "@mui/material/Divider";

function getMapItem(
  data: MapData,
  collectionIterator: number,
  itemIterator: number
) {
  return data.collections[collectionIterator].items[itemIterator];
}

const Point = () => {
  const { data, setSelected, selected } = useMapState();
  const { collection, item } = useParams();
  const { setData, expanded } = useContext(MainDrowerContext);
  const [mapItem, setMapItem] = useState<MapItem | null>(null);

  useEffect(
    function setMapItemData(): void {
      if (data && collection && item) {
        const collectionInt = parseInt(collection || "0");
        const itemInt = parseInt(item || "0");
        const mapItem = getMapItem(data, collectionInt, itemInt);
        setMapItem(mapItem);
        setData({
          title: getTranslation(mapItem.name, "pl"),
          description: getTranslation(mapItem.description, "pl"),
          image: mapItem.images[0]?.url,
        });
        if (
          selected?.collection !== collectionInt ||
          selected.item !== itemInt
        ) {
          setSelected({ collection: collectionInt, item: itemInt });
        }
      } else {
        setMapItem(null);
      }
    },
    [data, collection, item]
  );

  return (
    <>
      {mapItem && (
        <>
          <Collapse in={expanded}>
            <CardContent>
              <Typography paragraph color="text.secondary">
                {getTranslation(mapItem.description, "pl")}
              </Typography>
              {mapItem.images.map((image) => (
                <img
                  src={image.url}
                  key={image.url}
                  style={{ width: "100%" }}
                />
              ))}
            </CardContent>
          </Collapse>
        </>
      )}
    </>
  );
};

export default Point;
