import { Collapse, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { MainDrowerContext } from "../../provider/MainDrowerProvider";

export default function NotFound(): JSX.Element {
  const { setData, expanded } = useContext(MainDrowerContext);

  useEffect(function setDrowerHeaderInfo() {
    setData({
      image: "",
      title: "Not found!",
      description: "Application not found!",
    });
  }, []);

  return (
    <Collapse in={expanded}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography variant="h6">Application could not be found!</Typography>
          <Typography paragraph>
            The requested application could not be found in our configuration.
            Please contact applicaton developer.
          </Typography>
        </Grid>
      </Grid>
    </Collapse>
  );
}
