import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import "./App.css";
import MapStateProvider from "./provider/MapStateProvider";
import MapComponent from "./component/Map/MapComponent";
import MainDrower from "./component/MainDrawer/MainDrawer";
import MainDrowerProvider from "./provider/MainDrowerProvider";
import AppRouter from "./App.router";
import config from "./config.json";
import { RepoData, RepoDataContext } from "./provider/RepoDataProvider";
import { Box } from "@mui/material";

function App() {
  const [guideDataPath, setGuideDataPath] = useState<string | null>(null);

  const { setData } = useContext(RepoDataContext);

  const {
    isLoading: isLoadinginfo,
    error: infoError,
    data: infoData,
    isFetching: isFetchingInfo,
  } = useQuery<RepoData>(["repoInfo"], () =>
    axios.get(`${config.repos[0]}/list.json`).then((res) => res.data)
  );

  useEffect(
    function setRepoDataWhenReady() {
      if (infoData) {
        setData(infoData);
      }
    },
    [infoData]
  );

  return (
    <>
      <MapStateProvider>
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <MapComponent></MapComponent>
          <Box sx={{ height: 250 }} />
        </Box>
        <MainDrowerProvider>
          <MainDrower>
            <AppRouter />
          </MainDrower>
        </MainDrowerProvider>
      </MapStateProvider>
    </>
  );
}

export default App;
