import {
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  title: string;
  description: string;
  imageUrl?: string;
  expaneded: boolean;
  onExpandClick?: () => void;
}

const DrowerHeader = (props: Props): JSX.Element => {
  return (
    <>
      <CardMedia component="img" height="140" image={props.imageUrl} />
      <CardContent>
        {!props.expaneded && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography component="div" variant="h5" noWrap>
              {props.title}
            </Typography>
            <ExpandMoreIcon fontSize="large" />
          </Box>
        )}
        {props.expaneded && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography component="div" variant="h5">
              {props.title}
            </Typography>
            <ExpandLessIcon fontSize="large" />
          </Box>
        )}
      </CardContent>
    </>
  );
};

export default DrowerHeader;
