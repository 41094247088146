import { MapContainer, TileLayer } from "react-leaflet";

import { useMapState } from "../../provider/MapStateProvider";
import MapController from "../MapController/MapController";
import Markers from "../Markers/Markers";

import "./MapComponent.css";

const MapComponent = (): JSX.Element => {
  const {
    data,
    // selectedCollectionIterator,
    // selectedItemIterator,
    selected,
    setSelected,
  } = useMapState();

  function setSelectedItem(collectionIterator: number, itemIterator: number) {
    setSelected({
      collection: collectionIterator,
      item: itemIterator,
    });
  }

  return (
    <MapContainer
      className="map-component"
      center={[51.788953, 19.454477]}
      zoom={13}
      scrollWheelZoom={true}
    >
      {/* http://alexurquhart.github.io/free-tiles/ */}
      <TileLayer
        attribution=""
        url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
      <MapController selected={selected} />
      {data && (
        <Markers
          collections={data.collections}
          itemSelected={setSelectedItem}
        />
      )}
    </MapContainer>
  );
};

export default MapComponent;
