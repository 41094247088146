import { Route, Routes } from "react-router-dom";
import Guide from "../Guide/Guide";
import NotFound from "../NotFound/NotFound";
import Point from "../Point/Point";
import Branding from "./Branding";

export default function BrandingRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<Branding />}>
        <Route path="not-found" element={<NotFound />} />
        <Route path=":appId" element={<Guide />} />
        <Route path=":appId/:collection/:item" element={<Point />} />
      </Route>
    </Routes>
  );
}
