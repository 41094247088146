import L, { Icon, LatLngExpression } from "leaflet";
import { Marker } from "react-leaflet";

import { MapCollection, useMapState } from "../../provider/MapStateProvider";

function getIteratorByCoordinates(
  coordinates: number[],
  collections: MapCollection[]
): number[] {
  let collectionIterator = 0;
  let itemIterator = 0;

  collections.forEach((collection, collectionIndex) => {
    collection.items.forEach((item, itemIndex) => {
      if (item.coordinates === coordinates) {
        collectionIterator = collectionIndex;
        itemIterator = itemIndex;
      }
    });
  });

  return [collectionIterator, itemIterator];
}

interface Props {
  collections: MapCollection[];
  itemSelected?: (collectionIterator: number, itemIterator: number) => void;
}

const Markers = (props: Props): JSX.Element => {
  const { selected } = useMapState();

  function propagateItemSelected(coordinates: number[]): void {
    if (props.itemSelected) {
      const [collectionIterator, itemIterator] = getIteratorByCoordinates(
        coordinates,
        props.collections
      );
      props.itemSelected(collectionIterator, itemIterator);
    }
  }

  return (
    <>
      {props.collections.map((collection, collectionIterator) =>
        collection.items.map((item, itemIterator) => {
          const isSelected =
            collectionIterator === selected?.collection &&
            itemIterator === selected?.item;

          return (
            <Marker
              key={`marker-${item.coordinates.toString()}`}
              position={item.coordinates as LatLngExpression}
              eventHandlers={{
                click: () => propagateItemSelected(item.coordinates),
              }}
              icon={
                new Icon({
                  iconSize: L.point(25, 41),
                  iconAnchor: L.point(13, 41),
                  iconUrl: isSelected
                    ? "/selected-marker-icon.png"
                    : "/marker-icon.png",
                  iconRetinaUrl: isSelected
                    ? "/selected-marker-icon-2x.png"
                    : "/marker-icon-2x.png",
                  shadowUrl: "/marker-shadow.png",
                })
              }
            />
          );
        })
      )}
    </>
  );
};

export default Markers;
