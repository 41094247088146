import { ReactNode, useContext } from "react";
import { Card, Drawer } from "@mui/material";

import DrowerHeader from "../DrowerHeader/DrowerHeader";
import { MainDrowerContext } from "../../provider/MainDrowerProvider";

interface Props {
  children: ReactNode;
}

const MainDrower = (props: Props): JSX.Element => {
  const { data, expanded, setExpanded } = useContext(MainDrowerContext);

  function toggleDetails(): void {
    setExpanded(!expanded);
  }

  return (
    <Drawer
      anchor={"bottom"}
      open={true}
      variant="persistent"
      onClick={toggleDetails}
    >
      <Card sx={{ overflowY: "auto" }}>
        {data && (
          <DrowerHeader
            title={data.title}
            description={data.description}
            expaneded={expanded}
            imageUrl={data.image}
            onExpandClick={toggleDetails}
          />
        )}
        {props.children}
      </Card>
    </Drawer>
  );
};

export default MainDrower;
