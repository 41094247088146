import React, { createContext, useContext, useState } from "react";

export interface DrowerHeaderData {
  image: string;
  title: string;
  description: string;
}

export interface DrowerState {
  data: DrowerHeaderData | null;
  setData: (data: DrowerHeaderData | null) => void;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const defaultState: DrowerState = {
  data: null,
  setData: function (): void {
    throw new Error("MainDrowerProvider.setData:Function not implemented.");
  },
  expanded: false,
  setExpanded: function (): void {
    throw new Error("MainDrowerProvider.setExpanded:Function not implemented.");
  },
};

export const MainDrowerContext = createContext(defaultState);

interface Props {
  children?: React.ReactNode;
}

export default function MainDrowerContextProvider({
  children,
}: Props): JSX.Element {
  const [data, setData] = useState<DrowerHeaderData | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <MainDrowerContext.Provider
      value={{ data, setData, expanded, setExpanded }}
    >
      {children}
    </MainDrowerContext.Provider>
  );
}
