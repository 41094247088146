import { LatLngExpression } from "leaflet";
import { useEffect } from "react";
import { useMap, useMapEvent } from "react-leaflet";

import { MapStateSelected, useMapState } from "../../provider/MapStateProvider";

interface Props {
  selected: MapStateSelected | null;
}

const MapController = ({ selected }: Props): JSX.Element => {
  const map = useMap();
  const { data, setSelected } = useMapState();
  useMapEvent("click", () => setSelected(null));

  useEffect(
    function flyToCoordinates() {
      if (selected && data) {
        const selectedItem =
          data.collections[selected.collection].items[selected.item];
        map.flyTo(selectedItem.coordinates as LatLngExpression);
      }
    },
    [selected]
  );

  useEffect(
    function flyToCoordinates() {
      if (data?.coordinates) {
        map.flyTo(data.coordinates as LatLngExpression);
      }
    },
    [data]
  );

  return <></>;
};

export default MapController;
