interface Props {}

export default function PrivacyPolicy(props: Props): JSX.Element {
  return (
    <>
      <h1>Polityka prywatności</h1>
      <ol type="i">
        <h2>
          <li>Postanowienia ogólne</li>
        </h2>
        <ol>
          <li>
            Polityka prywatności określa, jak zbierane, przetwarzane i
            przechowywane są dane osobowe Użytkowników niezbędne do świadczenia
            usług drogą elektroniczną za pośrednictwem serwisu internetowego
            Conductro (dalej: Serwis).
          </li>
          <li>
            Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia i
            rozwoju usług w nim oferowanych.
          </li>
          <li>
            Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane
            zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE)
            2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych, dalej RODO) oraz ustawą o
            ochronie danych osobowych z dnia 10 maja 2018 r.
          </li>
        </ol>
        <h2>
          <li>Administrator danych</li>
        </h2>
        <p>
          Administratorem danych osobowych zbieranych poprzez Serwis jest Paweł
          Preczyński, adres poczty elektronicznej:
          pawel.preczynski+conductro@gmail.com (dalej: Administrator).
        </p>
        <h2>
          <li>Cel zbierania danych osobowych</li>
        </h2>
        <p>Serwis nie ma na celu zbierania danych Użytkowników.</p>
        <h2>
          <li>Rodzaj przetwarzanych danych osobowych</li>
        </h2>
        <p>Serwis nie przetwarza żadnych danych osobowych.</p>
        <h2>
          <li>Okres przetwarzania danych osobowych</li>
        </h2>
        <p>Dane osobowe Użytkowników nie będą przetwarzane przez Serwis.</p>
        <h2>
          <li>Udostępnianie danych osobowych</li>
        </h2>
        <ol>
          <li>Serwis nie udostępnia danych Użytkowników.</li>
          <li>
            Dane osobowe Użytkowników nie będą przekazywane poza teren
            Europejskiego Obszaru Gospodarczego (EOG).
          </li>
        </ol>
        <h2>
          <li>Prawa Użytkowników</li>
        </h2>
        <ol>
          <li>
            Użytkownik Serwisu ma prawo do: dostępu do treści swoich danych
            osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
            przenoszenia, wniesienia sprzeciwu wobec przetwarzania, cofnięcia
            zgody w każdej chwili (co nie ma wpływu na zgodność z prawem
            przetwarzania dokonanego w oparciu o zgodę przed jej cofnięciem).
          </li>
          <li>
            Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem
            wynikającym z wymienionych praw należy przesłać na adres
            pawel.preczynski+conductro@gmail.com.
          </li>
          <li>
            Administrator spełnia lub odmawia spełnienia żądania niezwłocznie –
            maksymalnie w ciągu miesiąca od jego otrzymania.
          </li>
          <li>
            Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych
            Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i
            wolności (RODO).
          </li>
        </ol>
        <h2>
          <li>Pliki cookies</li>
        </h2>
        <ol>
          <li>
            Serwis nie zbiera informacji za pomocą plików cookies – sesyjnych,
            stałych i podmiotów zewnętrznych.
          </li>
          <li>
            Użytkownik może określić zakres dostępu plików cookies do swojego
            urządzenia w ustawieniach przeglądarki.
          </li>
        </ol>
        <h2>
          <li>Zautomatyzowane podejmowanie decyzji i profilowanie</li>
        </h2>
        <ol>
          <li>
            Dane Użytkowników nie mogą być przetwarzane w zautomatyzowany sposób
            tak, że na skutek tego mogłyby zapaść wobec nich jakiekolwiek
            decyzje.
          </li>
          <li>
            Dane Użytkowników mogą być profilowane celem dostosowania treści i
            personalizacji oferty po wyrażeniu przez nich zgody.
          </li>
        </ol>
        <h2>
          <li>Postanowienia końcowe</li>
        </h2>
        <ol>
          <li>
            Administrator ma prawo do wprowadzenia zmian w Polityce prywatności,
            przy czym prawa Użytkowników nie zostaną ograniczone.
          </li>
          <li>
            Informacja o wprowadzonych zmianach pojawi się w formie komunikatu
            dostępnego w Serwisie.
          </li>
          <li>
            W sprawach nieuregulowanych w niniejszej Polityce prywatności
            obowiązują przepisy RODO i przepisy prawa polskiego.
          </li>
        </ol>
      </ol>
    </>
  );
}
