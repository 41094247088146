import { LatLngExpression } from "leaflet";
import React, { createContext, useContext, useState } from "react";

export interface TranslationString {
  [key: string]: string;
}

export interface MapItem {
  coordinates: number[];
  description: TranslationString | string;
  name: TranslationString | string;
  locationDescription: TranslationString | string;
  images: {
    url: string;
  }[];
}

export interface MapCollection {
  items: MapItem[];
}

export interface MapData {
  collections: MapCollection[];
  name: TranslationString | string;
  description: TranslationString | string;
  interface: {
    author: {
      name: TranslationString | string;
      description: TranslationString | string;
    };
  };
  coordinates?: number[];
  image?: string;
  icon?: string;
  version: string;
}

export interface MapStateSelected {
  collection: number;
  item: number;
}
interface MapState {
  data: MapData | null;
  setData: (value: MapData | null) => void;
  selected: MapStateSelected | null;
  setSelected: (value: MapStateSelected | null) => void;
}

const defaultState: MapState = {
  data: null,
  selected: null,
  setSelected: () => {
    throw new Error("MapStateProvider.setSelected: Function not implemented!");
  },
  setData: function (value: MapData | null): void {
    throw new Error("MapStateProvider.setData: Function not implemented.");
  },
};

/* The context itself */
const MapStateContext = createContext(defaultState);

interface Props {
  children?: React.ReactNode;
  state?: Partial<MapState>;
}

/* The provider lets you change the provided components */
const MapStateProvider = ({ children, state }: Props) => {
  const [selected, setSelected] = useState<MapStateSelected | null>(null);
  const [data, setData] = useState<MapData | null>(null);

  return (
    <MapStateContext.Provider
      value={{
        ...defaultState,
        selected,
        setSelected,
        data,
        setData,
        ...state,
      }}
    >
      {children}
    </MapStateContext.Provider>
  );
};

/* A custom hook to get access to the provided components */
export const useMapState = () => useContext(MapStateContext);

/* The components provider itself */
export default MapStateProvider;

export function getTranslation(
  translation: TranslationString | string,
  language: string
): string {
  if (translation === null || translation === undefined) {
    return "";
  }

  if (isTranslationString(translation)) {
    return translation;
  }

  return translation[language];
}

function isTranslationString(
  translation: TranslationString | string
): translation is string {
  return translation.length !== undefined;
}
